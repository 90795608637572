import React, { useContext, useEffect, useState } from "react";
import DataContext from "../context/DataContext";
import { Badge, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor, faArrowAltCircleDown, faArrowAltCircleUp, faDumbbell, faEdit, faRunning } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
// import DataTable from "react-data-table-component";


const ActivityTemplate = () => {
  const {
    workoutTypeList,
    workoutCategoryList,
    workoutMuscleList,
    workoutExerciseList,
    mapMuscleExerciseList,
    activityTemplateList,
    setActivityTemplateList,
  } = useContext(DataContext);

  const [selectedExerciseList, setSelectedExerciseList] = useState([])
  const [typeShow, setTypeShow] = useState(false)
  
  useEffect(() => {
    if (!workoutMuscleList.length || !workoutExerciseList.length || !mapMuscleExerciseList) {
      return; 
    }
    
    const exerciseMuscleList = mapMuscleExerciseList;

    const filterExercises = (template, exerciseList) => {
      const { wtype_id, wcategory_id, muscle_id } = template;
      return exerciseList.filter(data =>
        parseInt(data.workoutType_id) === parseInt(wtype_id) &&
        (wcategory_id === null || parseInt(data.workoutCategory_id) === parseInt(wcategory_id)) &&
        (muscle_id === null || parseInt(data.workoutMuscle_id) === parseInt(muscle_id))
      );
    };

    const filterExercisesList = (filteredExercises)=>{

      if (filteredExercises.length) {
        const exercisesList = filteredExercises.map((exercise) => {
          return workoutExerciseList.find(data => parseInt(data.id) === parseInt(exercise.exercise_id)) || {};
        });        
        return exercisesList;
      }
    }
  
    const newActivityTemplate = activityTemplateList.map(template => {
      const { wtype_id, wcategory_id, muscle_id } = template;
  
      const filteredExercises = filterExercises(template, exerciseMuscleList);
      
      const uniqueFilteredExercises = filteredExercises.filter(
        (object, index, self) =>
          index === self.findIndex((o) => o.exercise_id === object.exercise_id)
      );

      const exercisesList = filterExercisesList(uniqueFilteredExercises);
     
      const countEmptyEquipmentExercises = exercisesList?.filter((exercise) => {
        return exercise.equipment.length === 0;
      }) || 0;
    
      const countEquipmentExercises = exercisesList?.filter((exercise) => {
        return exercise.equipment?.length;
      }) || 0;

      let countDifficultyExercisesList = countExercisesByDifficulty(exercisesList || []);

      /* with Equipment and within difficulty */
           
      let exersisesWithDumbellEquipment = [];
      let exersisesWithKettlebellEquipment = [];
      let exersisesWithBandEquipment = [];

      (countEquipmentExercises || []).forEach((exercise) => {
        if (exercise.equipment) {
          if (exercise.equipment.includes("dumbbell")) {
            exersisesWithDumbellEquipment.push(exercise)
          }
          if (exercise.equipment.includes("kettlebell")) {
            exersisesWithKettlebellEquipment.push(exercise)
          }
          if (exercise.equipment.includes("band")) {
            exersisesWithBandEquipment.push(exercise)
          }
        }
      });

      let countDifficultyWithDumbellEquipment = countExercisesByDifficulty(exersisesWithDumbellEquipment || []);
      let countDifficultyWithKettlebellEquipment = countExercisesByDifficulty(exersisesWithKettlebellEquipment || []);
      let countDifficultyWithBandEquipment = countExercisesByDifficulty(exersisesWithBandEquipment || []);

      let countExercisesWithDumbellEquipment = 0;
      let countExercisesWithBandEquipment = 0;
      let countExercisesWithKettlebellEquipment = 0;
      
      (countEquipmentExercises || []).forEach((exercise) => {
        if (exercise.equipment) {
          if (exercise.equipment.includes("dumbbell")) {
            countExercisesWithDumbellEquipment++;
          }
          if (exercise.equipment.includes("band")) {
            countExercisesWithBandEquipment++;
          }
          if (exercise.equipment.includes("kettlebell")) {
            countExercisesWithKettlebellEquipment++;
          }
        }
      });

      function countExercisesByDifficulty(exercises) {
        let countEasy = 0;
        let countMedium = 0;
        let countHard = 0;
      
        exercises.forEach((exercise) => {
          if (exercise.difficulty) {
            switch (exercise.difficulty) {
              case "easy":
                countEasy++;
                break;
              case "medium":
                countMedium++;
                break;
              case "hard":
                countHard++;
                break;
              default:
                break;
            }
          }
        });
      
        return { easy: countEasy, medium: countMedium, hard: countHard };
      }
      
      let countDifficultyWithEquipment = countExercisesByDifficulty(countEquipmentExercises || []);
      let countDifficultyWithoutEquipment = countExercisesByDifficulty(countEmptyEquipmentExercises || []);

      const matchedWorkoutType = workoutTypeList.find(data => parseInt(data.id) === parseInt(wtype_id)) || {};
      const matchedWorkoutCategory = workoutCategoryList.find(data => parseInt(data.id) === parseInt(wcategory_id)) || {};
      const matchedWorkoutMuscle = workoutMuscleList.find(data => parseInt(data.id) === parseInt(muscle_id)) || {};

      return {
        ...template,
        wtype_name: matchedWorkoutType.name,
        wcategory_name: matchedWorkoutCategory.name,
        muscle_name: matchedWorkoutMuscle.name,
        exercise_list: uniqueFilteredExercises || [],
        exercise_count: uniqueFilteredExercises.length || 0,
        exercises: exercisesList || [],
        exercises_count: exercisesList?.length || 0,
        exercises_difficulty_all: countDifficultyExercisesList,

        exercises_without_equipment : countEmptyEquipmentExercises.length || 0,
        exercises_with_equipment: countEquipmentExercises.length || 0,

        exercises_with_dumbell_equipment : countExercisesWithDumbellEquipment,
        exercises_with_band_equipment : countExercisesWithBandEquipment,
        exercises_with_kettlebell_equipment : countExercisesWithKettlebellEquipment,

        exercises_difficulty_with_dumbell_equipment : countDifficultyWithDumbellEquipment,
        exercises_difficulty_with_kettlebell_equipment : countDifficultyWithKettlebellEquipment,
        exercises_difficulty_with_band_equipment : countDifficultyWithBandEquipment,

        exercise_difficulty_with_equipment: countDifficultyWithEquipment,
        exercise_difficulty_without_equipment: countDifficultyWithoutEquipment
      };
    });

     setActivityTemplateList(newActivityTemplate);
  }, [workoutMuscleList, workoutExerciseList, mapMuscleExerciseList]);

  //Workout Objects
  const [typeObj, setTypeObj] = useState(workoutTypeList);
  const [categoryObj, setCategoryObj] = useState(workoutCategoryList);
  const [muscleObj, setMuscleObj] = useState(workoutMuscleList);

  useEffect(() => {
    if (workoutTypeList) {
      const wtl = workoutTypeList.map((obj, index) => {
        return {
          ...obj,
          value: obj.id,
          label: obj.name,
        };
      });
      setTypeObj(wtl);
    }
  }, [workoutTypeList]);

  useEffect(() => {
    if (workoutCategoryList) {
      const wcl = workoutCategoryList.map((obj, index) => {
        return {
          ...obj,
          value: obj.id,
          label: obj.name,
        };
      });
      setCategoryObj(wcl);
    }
  }, [workoutCategoryList]);

  useEffect(() => {
    if (workoutMuscleList) {
      const wml = workoutMuscleList.map((obj) => {
        return {
          ...obj,
          value: obj.id,
          label: obj.name,
        };
      });
      setMuscleObj(wml);
    }
  }, [workoutMuscleList]);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      "text-align": "left",
      'z-index':"99999999",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };

  const [defaultDays, setDefaultDays] = useState([]);
  const [defaultTypes, setDefaultTypes] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [defaultMuscle, setDefaultMuscle] = useState([]);

  const [selectedWDays, setSelectedWDays] = useState([]);
  const [selectedWTypes, setSelectedWTypes] = useState([]);
  const [selectedWCategory, setSelectedWCategory] = useState([]);
  const [selectedMuscle, setSelectedMuscle] = useState([]);

  const [expanded, setExpanded] = useState(false);
 
  const toggleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  //Sort Config
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortTable = (key) => {
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...activityTemplateList].sort((a, b) => {
      if (key === "id") {
        a[key] = parseInt(a[key]);
        b[key] = parseInt(b[key]);
      }

      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setActivityTemplateList(sortedData);
    setSortConfig({ key, direction });
  };


  /* Datatable */
  /* const EquipmentCell = ({ equipmentData, difficultyData }) => (
    <div>
      {equipmentData || "-"}
      {equipmentData && (
        <div className="eq-count-table">
          <span>{difficultyData.easy || "-"}</span>
          <span>{difficultyData.medium || "-"}</span>
          <span>{difficultyData.hard || "-"}</span>
        </div>
      )}
    </div>
  ); */
            
  /* const columns = [
    {
      name: "#",
      selector: row => row.id,
      sortable: true,
      sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    {
      name: "Status",
      selector: row => row.exercise_count,
      cell: (row) => {
        let returnText =
          parseInt(row.exercise_count) < 3 ? (
            <Badge bg="danger" className="mx-1">
              &nbsp;
            </Badge>
          ) : parseInt(row.exercise_count) < 12 ? (
            <Badge bg="warning" className="mx-1">
              &nbsp;
            </Badge>
          ) : (
            <Badge bg="success" className="mx-1">
              &nbsp;
            </Badge>
          );

        return returnText;
      },
      sortable: true,
    },
    { name: "Workout Days", selector: row => row.n_workout_days, sortable: true },
    { name: "Schedule", selector: row => row.schedule, sortable: true },
    { name: "Day", selector: row => row.day_type, sortable: true },
    { name: "Workout Type", selector: row => row.wtype_name, sortable: true },
    { name: "Category", selector: row => row.wcategory_name, sortable: true },
    { name: "Muscle", selector: row => row.muscle_name, sortable: true },
    {
      name: "Exercise Count",
      selector: row => row.exercise_count,
      cell: (row) => (
        <EquipmentCell
          equipmentData={row.exercise_count}
          difficultyData={row.exercises_difficulty_all}
        />
      ),
      sortable: true,
    },
    {
      name: "Without Equipment",
      selector: row => row.exercises_without_equipment,
      cell: (row) => (
        <EquipmentCell
          equipmentData={row.exercises_without_equipment}
          difficultyData={row.exercise_difficulty_without_equipment}
        />
      ),
      sortable: true,
    },
    {
      name: "With Equipment",
      selector: row => row.exercises_with_equipment,
      cell: (row) => (
        <EquipmentCell
          equipmentData={row.exercises_with_equipment}
          difficultyData={row.exercise_difficulty_with_equipment}
        />
      ),
      sortable: true,
    },
    {
      name: "With Dumbell Equipment",
      selector: row => row.exercises_with_dumbell_equipment,
      cell: (row) => (
        <EquipmentCell
          equipmentData={row.exercises_with_dumbell_equipment}
          difficultyData={row.exercises_difficulty_with_dumbell_equipment}
        />
      ),
      sortable: true,
    },
    {
      name: "With Kettlebell Equipment",
      selector: row => row.exercises_with_kettlebell_equipment,
      cell: (row) => (
        <EquipmentCell
          equipmentData={row.exercises_with_kettlebell_equipment}
          difficultyData={row.exercises_difficulty_with_kettlebell_equipment}
        />
      ),
      sortable: true,
    },
    {
      name: "With Band Equipment",
      selector: row => row.exercises_with_dumbell_equipment,
      cell: (row) => (
        <EquipmentCell
          equipmentData={row.exercises_with_band_equipment}
          difficultyData={row.exercises_difficulty_with_band_equipment}
        />
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          variant="outline-primary"
          size="sm"
          className="mx-2"
          disabled={parseInt(row.exercise_count) > 0 ? false : true}
          onClick={() => {
            const exerciseMuscleList = row.exercise_list?.map(
              (exercise) => {
                const updatedResponse =
                  mapMuscleExerciseList.filter(
                    (data) =>
                      parseInt(data.exercise_id) ===
                      parseInt(exercise.exercise_id)
                  );
                const exerciseObj = workoutExerciseList.find(
                  (data) =>
                    parseInt(data.id) ===
                    parseInt(exercise.exercise_id)
                );

                return updatedResponse.length > 0
                  ? { muscleobj: updatedResponse, ...exerciseObj } //{ ...exercise, muscleobj: updatedResponse, ...exerciseObj }
                  : exercise;
              }
            );

            setSelectedExerciseList(exerciseMuscleList);
            setTypeShow(true);
          }}
        >
          View
        </Button>
      ),
    },
  ]; */

  return (
    <div>
      <div
        className={`mb-2 expandable-block ${
          expanded ? "expanded" : ""
        } activity-filter-section`}
      >
        <div className="block-header d-flex justify-content-between align-items-center">
          <h4 className="mb-0">
            <FontAwesomeIcon icon={faRunning} /> ActivityTemplate
          </h4>
          <Button variant="outline-success" onClick={toggleExpand}>
            {expanded ? (
              <FontAwesomeIcon icon={faArrowAltCircleUp} />
            ) : (
              <FontAwesomeIcon icon={faArrowAltCircleDown} />
            )}{" "}
            Filter Activity
          </Button>
        </div>

        <div className="block-content">
          <Row className="pt-2">
            <Col md={3} className="text-start">
              <Form.Group
                className="mb-3 text"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Workout Days</Form.Label>
                <Select
                  maxMenuHeight={200}
                  menuPlacement="auto"
                  className="react-select"
                  isMulti
                  name="w_type"
                  options={[
                    {
                      value: "3",
                      label: "3",
                    },
                    {
                      value: "4",
                      label: "4",
                    },
                    {
                      value: "5",
                      label: "5",
                    },
                    {
                      value: "6",
                      label: "6",
                    },
                  ]}
                  styles={selectStyles}
                  value={defaultDays}
                  onChange={(e) => {
                    setDefaultDays(e);
                    const tempArry = [];
                    e?.forEach((obj, index) => {
                      tempArry.push(obj.value);
                    });
                    setSelectedWDays(tempArry);
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={3} className="text-start">
              <Form.Group
                className="mb-3 text"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Type</Form.Label>
                <Select
                  maxMenuHeight={200}
                  menuPlacement="auto"
                  className="react-select"
                  isMulti
                  name="w_type"
                  options={typeObj}
                  styles={selectStyles}
                  value={defaultTypes}
                  onChange={(e) => {
                    setDefaultTypes(e);
                    const tempArry = [];
                    e?.forEach((obj, index) => {
                      tempArry.push(obj.value);
                    });
                    setSelectedWTypes(tempArry);
                  }}
                />
              </Form.Group>
            </Col>
            
            <Col md={3} className="text-start">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Category</Form.Label>
                <Select
                  maxMenuHeight={200}
                  className="react-select"
                  isMulti
                  name="w_category"
                  options={categoryObj}
                  value={defaultCategory}
                  styles={selectStyles}
                  onChange={(e) => {
                    setDefaultCategory(e);
                    const tempArry = [];
                    e?.forEach((obj, index) => {
                      tempArry.push(obj.value);
                    });
                    setSelectedWCategory(tempArry);
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={3} className="text-start">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Muscle</Form.Label>
                <Select
                  maxMenuHeight={200}
                  className="react-select text-left"
                  isMulti
                  name="w_muscle"
                  options={muscleObj}
                  value={defaultMuscle}
                  styles={selectStyles}
                  onChange={(e) => {
                    setDefaultMuscle(e);
                    const tempArry = [];
                    e?.forEach((obj, index) => {
                      tempArry.push(obj.value);
                    });
                    setSelectedMuscle(tempArry);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
      <div className="activity-table">
        <Table striped bordered hover>
          <thead>
            <tr className="header-row">
              <th onClick={() => sortTable("id")}>#</th>
              <th className="text-center">Status</th>
              <th
                onClick={() => sortTable("n_workout_days")}
                className="text-center"
              >
                Workout
                <br />
                Days
              </th>
              <th onClick={() => sortTable("schedule")} className="text-center">
                Schedule
              </th>
              <th onClick={() => sortTable("day")} className="text-center">
                Day
              </th>
              <th onClick={() => sortTable("day_type")}>Day Type</th>
              <th onClick={() => sortTable("wtype_name")}>Workout Type</th>
              <th onClick={() => sortTable("wcategory_name")}>Category</th>
              <th onClick={() => sortTable("muscle_name")}>Muscle</th>
              <th
                onClick={() => sortTable("exercise_count")}
                className="text-center"
              >
                <span className="px-1">
                  Exercise <br />
                  Count
                </span>
                <div className="eq-count-table">
                  <span>E</span>
                  <span>M</span>
                  <span>H</span>
                </div>
              </th>

              <th
                onClick={() => sortTable("exercises_without_equipment")}
                className="text-center"
              >
                <span className="px-1">
                  Without <br />
                  Equipment
                </span>
                <div className="eq-count-table">
                  <span>E</span>
                  <span>M</span>
                  <span>H</span>
                </div>
              </th>
              <th
                onClick={() => sortTable("exercises_with_equipment")}
                className="text-center"
              >
                <span className="px-1">
                  With <br />
                  Equipment
                </span>
                <div className="eq-count-table">
                  <span>E</span>
                  <span>M</span>
                  <span>H</span>
                </div>
              </th>
              {/* With Equipment */}
              <th
                onClick={() => sortTable("exercises_with_dumbell_equipment")}
                className="text-center"
              >
                <span className="px-1" style={{ whiteSpace: "nowrap" }}>
                  With Dumbell <br />
                  Equipment
                </span>
                <div className="eq-count-table">
                  <span>E</span>
                  <span>M</span>
                  <span>H</span>
                </div>
              </th>
              <th
                onClick={() => sortTable("exercises_with_kettlebell_equipment")}
                className="text-center"
              >
                <span className="px-1" style={{ whiteSpace: "nowrap" }}>
                  With Kettlebell <br />
                  Equipment
                </span>
                <div className="eq-count-table">
                  <span>E</span>
                  <span>M</span>
                  <span>H</span>
                </div>
              </th>
              <th
                onClick={() => sortTable("exercises_with_band_equipment")}
                className="text-center"
              >
                <span className="px-1">
                  With Band <br />
                  Equipment
                </span>
                <div className="eq-count-table">
                  <span>E</span>
                  <span>M</span>
                  <span>H</span>
                </div>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {activityTemplateList?.length ? (
              activityTemplateList
                .filter(
                  (item) =>
                    (selectedWDays.length === 0 ||                      
                      selectedWDays.includes((item.n_workout_days).toString())) &&
                    (selectedWTypes.length === 0 ||
                      selectedWTypes.includes(item.wtype_id)) &&
                    (selectedWCategory.length === 0 ||
                      selectedWCategory.includes(item.wcategory_id)) &&
                    (selectedMuscle.length === 0 ||
                      selectedMuscle.includes(item.muscle_id))
                )
                ?.map((obj, index) => (
                  <tr
                    key={index}
                    className={
                      parseInt(obj.exercise_count) < 3
                        ? "row-stage-1"
                        : parseInt(obj.exercise_count) < 12
                        ? "row-stage-2"
                        : "row-stage-3"
                    }
                  >
                    <td>{obj.id}</td>
                    <td className="text-center">
                      {parseInt(obj.exercise_count) < 3 ? (
                        <Badge bg="danger" className="mx-1">
                          &nbsp;
                        </Badge>
                      ) : parseInt(obj.exercise_count) < 12 ? (
                        <Badge bg="warning" className="mx-1">
                          &nbsp;
                        </Badge>
                      ) : (
                        <Badge bg="success" className="mx-1">
                          &nbsp;
                        </Badge>
                      )}
                    </td>
                    <td className="text-center">{obj.n_workout_days}</td>
                    <td className="text-center">{obj.schedule}</td>
                    <td className="text-center">{obj.day}</td>
                    <td>{obj.day_type}</td>
                    <td>{obj.wtype_id ? obj.wtype_name : ""}</td>
                    <td>{obj.wcategory_id ? obj.wcategory_name : "-"}</td>
                    <td>{obj.muscle_id ? obj.muscle_name : "-"}</td>

                    <td className="text-center">
                      {obj.exercise_count || "-"}
                      {obj.exercise_count ? (
                        <div className="eq-count-table">
                          <span>
                            {obj.exercises_difficulty_all.easy || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_all.medium || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_all.hard || "-"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td className="text-center">
                      {obj.exercises_without_equipment || "-"}
                      {obj.exercises_without_equipment ? (
                        <div className="eq-count-table">
                          <span>
                            {obj.exercise_difficulty_without_equipment.easy ||
                              "-"}
                          </span>
                          <span>
                            {obj.exercise_difficulty_without_equipment.medium ||
                              "-"}
                          </span>
                          <span>
                            {obj.exercise_difficulty_without_equipment.hard ||
                              "-"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td className="text-center">
                      {obj.exercises_with_equipment || "-"}
                      {obj.exercises_with_equipment ? (
                        <div className="eq-count-table">
                          <span>
                            {obj.exercise_difficulty_with_equipment.easy || "-"}
                          </span>
                          <span>
                            {obj.exercise_difficulty_with_equipment.medium ||
                              "-"}
                          </span>
                          <span>
                            {obj.exercise_difficulty_with_equipment.hard || "-"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td className="text-center">
                      {obj.exercises_with_dumbell_equipment || "-"}
                      {obj.exercises_with_dumbell_equipment ? (
                        <div className="eq-count-table">
                          <span>
                            {obj.exercises_difficulty_with_dumbell_equipment
                              .easy || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_with_dumbell_equipment
                              .medium || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_with_dumbell_equipment
                              .hard || "-"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td className="text-center">
                      {obj.exercises_with_kettlebell_equipment || "-"}
                      {obj.exercises_with_kettlebell_equipment ? (
                        <div className="eq-count-table">
                          <span>
                            {obj.exercises_difficulty_with_kettlebell_equipment
                              .easy || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_with_kettlebell_equipment
                              .medium || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_with_kettlebell_equipment
                              .hard || "-"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td className="text-center">
                      {obj.exercises_with_band_equipment || "-"}
                      {obj.exercises_with_band_equipment ? (
                        <div className="eq-count-table">
                          <span>
                            {obj.exercises_difficulty_with_band_equipment
                              .easy || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_with_band_equipment
                              .medium || "-"}
                          </span>
                          <span>
                            {obj.exercises_difficulty_with_band_equipment
                              .hard || "-"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mx-2"
                        disabled={
                          parseInt(obj.exercise_count) > 0 ? false : true
                        }
                        onClick={() => {
                          const exerciseMuscleList = obj.exercise_list?.map(
                            (exercise) => {
                              const updatedResponse =
                                mapMuscleExerciseList.filter(
                                  (data) =>
                                    parseInt(data.exercise_id) ===
                                    parseInt(exercise.exercise_id)
                                );
                              const exerciseObj = workoutExerciseList.find(
                                (data) =>
                                  parseInt(data.id) ===
                                  parseInt(exercise.exercise_id)
                              );

                              return updatedResponse.length > 0
                                ? { muscleobj: updatedResponse, ...exerciseObj } //{ ...exercise, muscleobj: updatedResponse, ...exerciseObj }
                                : exercise;
                            }
                          );

                          setSelectedExerciseList(exerciseMuscleList);
                          setTypeShow(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} /> View
                      </Button>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center">
                  No Activity Template found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Datatable */}

      {/* <div className="activity-table">
        <DataTable
          //title="Activity Template List"
          columns={columns}
          data={activityTemplateList}
          pagination={true}
          paginationPerPage={10}
          // paginationRowsPerPageOptions={[10, 20, 30]}
          // highlightOnHover={true}
          // dense={true}
          // responsive={true}
        />
      </div> */}

      {/* End-Datatable */}

      {/* Modal Section */}
      <Modal size="xl" show={typeShow} onHide={() => setTypeShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">View Mapped Exercises</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="activity-template">
          <Row>
            {selectedExerciseList?.map((exercise, index) => (
              <Col md={4} key={index} className="mb-2">
                <Card>
                  <Card.Img variant="top" src={exercise.img_url} />
                  <Card.Body>
                    <Card.Title className="text-capitalize mb-3">
                      {exercise.name}
                    </Card.Title>

                    <Row>
                      <Col md={5}>
                        <p>
                          <FontAwesomeIcon icon={faAnchor} /> Difficulty
                        </p>
                      </Col>
                      <Col md={7}>
                        <p className="text-capitalize text-end">
                          {exercise.difficulty}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <p>
                          <FontAwesomeIcon icon={faDumbbell} /> Equipment
                        </p>
                      </Col>
                      <Col md={7}>
                        <p className="text-capitalize text-end">
                          {exercise.equipment.length
                            ? exercise.equipment.toString().replace(/,/g, ", ")
                            : "None"}
                        </p>
                      </Col>
                    </Row>

                    <Row className="exercise-details border border-primary rounded pt-2">
                      <Col md={4} className="text-center">
                        <h6>Reps/Min</h6>
                        <h5>{exercise.reps_per_minute}</h5>
                      </Col>
                      <Col md={4} className="text-center">
                        <h6>Cal/Min</h6>
                        <h5>{exercise.calories_per_minute}</h5>
                      </Col>
                      <Col md={4} className="text-center text-capitalize">
                        <h6>Difficulty</h6>
                        <h5>{exercise.difficulty}</h5>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <h6>
                        Targetting Muscle's ({exercise.muscleobj?.length})
                      </h6>
                      <div className="muscle-section">
                        {exercise.muscleobj?.map((muscle, index) => (
                          <Row className="muscle-list py-1" key={index}>
                            <Col md={4}>
                              <Badge bg="primary" className="mx-1" key={index}>
                                {muscle.workoutType_name}
                              </Badge>
                            </Col>
                            <Col md={4} className="text-center">
                              <Badge bg="success" className="mx-1" key={index}>
                                {muscle.workoutCategory_name}
                              </Badge>
                            </Col>
                            <Col md={4} className="text-end">
                              <Badge bg="danger" className="mx-1" key={index}>
                                {muscle.workoutMuscle_name}
                              </Badge>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
      {/* End Modal Section */}
    </div>
  );
};

export default ActivityTemplate;
