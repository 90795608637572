import React, { useContext, useEffect, useState } from "react";
import API from "./api/API";
import DataContext from "../context/DataContext";
import DragAndDropExercise from "./DragAndDropExercise";
import { Modal, Form, Badge, Button, Row, Col, Tooltip, OverlayTrigger, Tabs, Tab } from "react-bootstrap";
// import { Table } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imageCompression from "browser-image-compression";
import {
  faAdd,
  faEdit,
  faPlusCircle,
  faRunning,
  faTrash,
  faSave,
  faMicrochip,
  faPersonBiking
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import 'react-toastify/dist/ReactToastify.css';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DataTable from "react-data-table-component";
// import preloaderGif4 from "../assets/img/preloader-4.gif";
// import preloaderGif5 from "../assets/img/preloader-5.gif";
// import preloaderGif6 from "../assets/img/preloader-6.gif";
import preloaderGif7 from "../assets/img/preloader-7.gif";

const WorkoutCreation = () => {

  const {
    workoutTypeList,
    workoutExerciseList,
    workoutsList,
    setWorkoutsList,
    workoutsAIList,
    setWorkoutsAIList,
    mapWorkoutExerciseList,
    setMapWorkoutExerciseList,
    workoutExerciseObj, 
    setWorkoutExerciseObj,
    ShowNotification
  } = useContext(DataContext);

  //Entity State
  const [typeObj, setTypeObj] = useState(workoutTypeList);
  const [workoutList, setWorkoutList] = useState(workoutsList);
  const [isLoading, setIsLoading] = useState(false);

  //Mapped Objects

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (workoutTypeList) {
      setTypeObj(workoutTypeList);
    }
  }, [workoutTypeList]);

  useEffect(() => {
    if (workoutsList.length > 0) {
      workoutsList.forEach((obj, index) => {
        let workoutType_name = workoutTypeList?.find(
          (Obj) => parseInt(Obj.id) === parseInt(obj.wtype_id)
        );
    
        if (!!workoutType_name) {
          obj["wtype_name"] = workoutType_name.name;
        }
      });
    
      setWorkoutList(workoutsList);
    }
  }, [workoutsList, workoutTypeList]);
  
  useEffect(() => {
    if (workoutsAIList.length > 0) {
      workoutsAIList.forEach((obj, index) => {
        let workoutType_name = workoutTypeList?.find(
          (Obj) => parseInt(Obj.id) === parseInt(obj.wtype_id)
        );
    
        if (!!workoutType_name) {
          obj["wtype_name"] = workoutType_name.name;
        }
      });
    
      setWorkoutsAIList(workoutsAIList);
    }
  }, [workoutsAIList, workoutTypeList]);

  // Workout Creation State
  const [workout, setWorkout] = useState("");
  const [workoutdescription, setWorkoutdescription] = useState("");
  const [workoutCategory, setWorkoutCategory] = useState([]);
  const [workoutDuration, setWorkoutDuration] = useState("");
  const [workoutSets, setWorkoutSets] = useState("");
  const [workoutDifficulty, setWorkoutDifficulty] = useState("");
  const [workoutTypeID, setWorkoutTypeID] = useState("");
  const [workoutManual, setWorkoutManual] = useState(true);
  
  const [workoutImage, setWorkoutImage] = useState("");
  const [defaultWorkoutImage, setDefaultWorkoutImage] = useState("");
  // const [workoutImageFile, setWorkoutImageFile] = useState("");
  const [workoutAudioUrl, setWorkoutAudioUrl] = useState("");  

  const [searchWorkout, setSearchWorkout] = useState("");
  const [searchWorkoutAI, setSearchWorkoutAI] = useState("");
  const [searchManual, setSearchManual] = useState(false);

  const [workoutID, setWorkoutID] = useState(0); // Create or Edit
  const [typeShow, setTypeShow] = useState(false);

  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState([]);

  const categoryOptions = [
    { value: "run", label: "Run" },
    { value: "walk", label: "Walk" },
    { value: "cycle", label: "Cycle" },
    { value: "hiit", label: "Hiit" },
    { value: "row", label: "Row" },
  ];

  useEffect(() => {
    setDefaultWorkoutImage(
      "https://smitchcare.azureedge.net/aicoach/activity/workout/exercise/default.png"
    );
  }, []);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    // setWorkoutImageFile(file);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };

    const reader = new FileReader();

    try {
      if (file) {
       
        const image = new Image();
        image.src = URL.createObjectURL(file);
  
        // image.onload = () => {
        //   if (image.width / image.height !== 16 / 9) {
        //     console.error('Image aspect ratio must be 16:9.');
        //   } else if (file.size > 5 * 1024 * 1024) { // 5 MB limit
        //     console.error('Image size must be under 5MB.');
        //   } else {
        //     
        //   }
        // };

        const compressedFile = await imageCompression(file, options);
        reader.readAsDataURL(compressedFile); //URL.createObjectURL(file)
      }
    } catch (error) {
      console.error("Image compression error:", error);
    }

    reader.onloadend = () => {
      setWorkoutImage(reader.result);
    };
  };
  
  //Get Exercises to Mapping Workout
  function getCategoryOptions(workoutID) {
    
    let exerciseListSelect = [];

    Object.keys(workoutExerciseList).forEach(function (index) {
      const temparray = workoutExerciseObj.filter(
        (ed) =>
          parseInt(ed.exercise_id) === parseInt(workoutExerciseList[index].id)
      );

      if (!temparray.length) {
        exerciseListSelect.push({
          value: workoutExerciseList[index].id,
          label: workoutExerciseList[index].name,
          image: workoutExerciseList[index].img_url,
          reps: workoutExerciseList[index].reps_per_minute,
          difficulty: workoutExerciseList[index].difficulty,
        });
      }
    });

    return exerciseListSelect;
  }

  //Add Workout List
  const handleSubmitWorkout = () => {
    if (workout.trim().length > 0) {
      if (workoutID === 0) {

        let workoutImgURL = workoutImage;

        if (!workoutImage) {
          workoutImgURL = defaultWorkoutImage;
        }

        API.post("/workouts", {
          name: workout,
          description: workoutdescription,
          category: workoutCategory,
          duration: workoutDuration,
          sets: workoutSets,
          difficulty: workoutDifficulty,
          img_url: workoutImgURL,
          wtype_id: workoutTypeID,
          is_manual: workoutManual,
          content_url: workoutAudioUrl,
        })
          .then((response) => {
            let responseData = response.data.data.aic_workout;
            responseData["exercise_obj"] = [];
            
            setWorkoutID(responseData.id);
            setWorkout(responseData.name);
            setWorkoutdescription(responseData.description);
            setWorkoutDuration(responseData.duration);
            setWorkoutSets(responseData.sets);
            setWorkoutDifficulty(responseData.difficulty);
            setWorkoutTypeID(responseData.wtype_id);
            setWorkoutManual(responseData.is_manual);
            setWorkoutImage(responseData.img_url);

            setWorkoutList([...workoutList, responseData]);
            setWorkoutsList([...workoutsList, responseData]);
            
            setIsEdit(true);
            //handleReset();
          })
          .catch((error) => {
            ShowNotification("warn", error.response.data.message);
          });
      } else {

        API.patch(`/workouts/${workoutID}`, {
          workout_id: workoutID,
          name: workout,
          description: workoutdescription,
          category: workoutCategory,
          duration: workoutDuration,
          sets: workoutSets,
          difficulty: workoutDifficulty,
          wtype_id: workoutTypeID,
          img_url: workoutImage,
          is_manual: workoutManual,
          content_url: workoutAudioUrl,
        })
          .then((response) => {
            let responseData = response.data.data.aic_workout;
           
            const updatedList = workoutList.map((obj) => {
              if (obj.id === workoutID) {
                responseData["exercise_obj"] = obj.exercise_obj;                
                return responseData;
              } else {
                return obj;
              }
            });

            setWorkoutList(updatedList);
            setWorkoutsList(updatedList);
            setTypeShow(false);
            setWorkoutID(0);
            setWorkout("");
            handleReset();
          })
          .catch((error) => {
            ShowNotification("warn", error.response.data.message);
          });
      }
    } else {
      ShowNotification("info", "Please Check If Already Entered");
    }
  };

  //Remove Workout List
  const handleDelete = (id, jsonURL) => {
    Swal.fire({
      title: "Do you want to Delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(`/${jsonURL}/${id}`).then((response) => {
          ShowNotification("success", "Deleted Successfully!");

          if (jsonURL === "workouts") {
            setWorkoutList(workoutList.filter((obj) => obj.id !== id));
            setWorkoutsList(workoutList.filter((obj) => obj.id !== id));
          }
        });
      } else if (result.isDenied) {
        ShowNotification("info", "Changes are not saved!");
      }
    });

    /* */
  };

  //Reset Workout Create Fields
  const handleReset = () => {
    setWorkout("");
    setWorkoutdescription("");
    setWorkoutCategory("");
    setWorkoutDuration("");
    setWorkoutDifficulty("");
    setWorkoutManual(true);
    setWorkoutSets("");
    setWorkoutTypeID("");
    setDefaultSelectedCategory([]);
    setWorkoutExerciseObj([]);
    setWorkoutImage("");
  };
  
  const [selectedDefaultExercise, setSelectedDefaultExercise] = useState("");

  const [selectedExercise, setSelectedExercise] = useState("");
  const [selectedExerciseName, setSelectedExerciseName] = useState("");

  //Wotkout Type Label Format
  const formatOptionLabel = ({ label, difficulty }, { context }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {label}{" "}
        <Badge
          bg={
            difficulty === "easy"
              ? "success"
              : difficulty === "medium"
              ? "primary"
              : "danger"
          }
          style={{ textTransform: "capitalize", fontStyle: "normal" }}
        >
          {difficulty}
        </Badge>
      </div>
    </div>
  );

  //Add Exercise to Workout
  const handleAddExercise = () => {

    let exerciseCount = mapWorkoutExerciseList.filter(
      (data) => parseInt(data.workout_id) === parseInt(workoutID)
    );

    if(selectedExercise){
       
     API.post("/workout-exercise", {
      workout_id: workoutID,
      exercise_id: selectedExercise,
      reps: "10",
      sequence: (exerciseCount.length).toString()
    })
      .then((response) => {

        let responseData = response.data.data.aic_workout_exercise;
        let exerciseDetails = workoutExerciseList?.find(
          (Obj) => parseInt(Obj.id) === parseInt(responseData.exercise_id)
        );

        exerciseDetails["aic_exercise_details"] = [];

        let responseObj = {
          id: responseData.id,
          workout_id: workoutID,
          exercise_id: responseData.exercise_id,
          name: selectedExerciseName,
        };

        setMapWorkoutExerciseList([...mapWorkoutExerciseList, responseObj]);

        setWorkoutExerciseObj([
          ...workoutExerciseObj,
          { ...responseObj, aic_exercise: exerciseDetails },
        ]);

        ShowNotification("success", "Mapped Successfully");
        
        handleResetMapping()
      })
      .catch((error) => {
        ShowNotification("warn", error.response.data.message);
      });

    }else{
      ShowNotification("warn", "Please select Exercise");
    }

  }

  //Remove Exercise to Workout
  const handleRemoveExercise = (relationshipID, workoutID, exerciseID, workoutExerciseObjList) =>{

    API.delete(
      `/workout-exercise/${relationshipID}`,{
        data: {
          workout_id: workoutID,
          exercise_id: exerciseID,
        },
      }
    ).then((response) => {

      const filteredExercises = workoutExerciseObjList.filter(
        (obj) => parseInt(obj.id) !== parseInt(relationshipID)
      );

      setMapWorkoutExerciseList((prevList) =>
        prevList.filter((obj) => parseInt(obj.id) !== parseInt(relationshipID))
      );

      setWorkoutExerciseObj(filteredExercises);
      
      ShowNotification("success", "Remove Successfully");

    });
  }

  //Reset Mapping Fields
  const handleResetMapping = () => {    
    setSelectedExercise("");
    setSelectedDefaultExercise("");
  }

  const sortExericseOrder = () => {

    const sortObject = workoutExerciseObj.map((data, index) => ({
      id: data.id,
      sequence: (index + 1).toString(),
      workout_id: data.workout_id,
      exercise_id: data.exercise_id,
    }));

    const sortExerciseDetails = workoutExerciseObj.flatMap(
      (obj, exIndex) =>
        obj.aic_exercise.aic_exercise_details?.map((data) => ({
          id: data.id,
          sequence: (exIndex + 1).toString(),
          workout_id: data.workout_id,
          exercise_id: data.exercise_id,
        }))
    );

    API.post("/sort-workout-exercises", {
      sort_workout_exercises: sortObject,
      sort_exercise_details: sortExerciseDetails
    })
      .then((response) => {
        let responseData = response.data.data.aic_workout_exercise;
      
        ShowNotification("success", "Sort Successfully");

        responseData?.map((data, index) => {
          return setMapWorkoutExerciseList((prevArray) => {
            const updatedArray = prevArray.map((item) =>
              item.id === data.id
                ? { ...item, sequence: data.sequence }
                : item
            );
            return updatedArray;
          });
        });

      })
      .catch((error) => {
        ShowNotification("warn", error.response.data.message);
      });
  }

  const ExercisePreloader = () => {
    return (
      <div className="exercise-preloader-section">
        <img src={preloaderGif7} alt="Loading..." />
        <span><br/>Loading...</span>
      </div>
    );
  };


  /* Datatable */


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props}
    </Tooltip>
  );

  const workoutColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) => parseInt(a.id) - parseInt(b.id),
      width: "10%",
      wrap: true,
    },
    {
      name: "Workout Name",
      selector: (row) => row.name.replace(/_/g, " "),
      sortable: true,
      width: "20%",
    },
    {
      name: "Workout Type",
      selector: (row) => row.wtype_name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Category",
      selector: (row) => row.category.toString(),
      sortable: true,
    },
    { name: "Difficulty", selector: (row) => row.difficulty, sortable: true },
    {
      name: "Duration",
      selector: (row) =>parseFloat(row.duration / 60).toFixed(2) + " Mins",
      sortable: true,
    },
    { name: "Sets", selector: (row) => row.sets, sortable: true },
    {
      name: "Exercises",
      selector: (row) => row.exercise_obj?.length,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <OverlayTrigger overlay={renderTooltip("Edit Workout")}>
            <Button
              variant="outline-primary"
              size="sm"
              className="mx-2"
              disabled={!row.is_manual}
              onClick={() => {
                setIsEdit(true);
                setTypeShow(true);

                setWorkoutID(row.id);
                setWorkout(row.name);
                setWorkoutdescription(row.description);

                const result = categoryOptions.filter((data) =>
                  row.category.includes(data.value)
                );
                setDefaultSelectedCategory(result);

                setWorkoutCategory([row.category[0]]);
                setWorkoutDifficulty(row.difficulty);
                setWorkoutManual(row.is_manual);
                setWorkoutDuration(row.duration.toString());
                setWorkoutSets(row.sets.toString());
                setWorkoutTypeID(row.wtype_id);
                setWorkoutImage(row.img_url);
                setSelectedExercise("");
                setSelectedDefaultExercise("");
                setIsLoading(true);
                
                API.get(`/workout-details/${row.id}`)
                  .then((response) => {
                    let responseData = response.data.data.aic_workout_details;
                    
                    const sortedData = responseData.map((exercise) => ({
                      ...exercise,
                      aic_exercise: {
                        ...exercise.aic_exercise,
                        aic_exercise_details: exercise.aic_exercise.aic_exercise_details.sort(
                          (a, b) => a.sets - b.sets
                        ),
                      },
                    }));

                    setWorkoutExerciseObj(sortedData.sort((a, b) => a.sequence - b.sequence));
                    
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.error("Error fetching Workout Details :", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
              {/* Edit */}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={renderTooltip("Delete Workout")}>
            <Button
              variant="outline-danger"
              size="sm"
              className="mx-2"
              disabled={!row.is_manual}
              onClick={() => {
                handleDelete(row.id, "workouts");
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              {/* Delete */}
            </Button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  const [dtWorkoutList, setDtWorkoutList] = useState([]);
  const [dtWorkoutAIList, setDtWorkoutAIList] = useState([]);

  useEffect(()=>{
    if(workoutList){

      let dtList = workoutList?.filter(
        (item) =>
          (searchWorkout === "") ||
          ((item.name.toLowerCase().includes(searchWorkout.toLowerCase()) ||
            item.id.toLowerCase().includes(searchWorkout)))
      );

      setDtWorkoutList(dtList)
    }

  },[workoutList, searchWorkout]);

  useEffect(()=>{
    if(workoutsAIList){

      let dtList = workoutsAIList?.filter(
        (item) =>
          (searchWorkoutAI === "") ||
          ((item.name.toLowerCase().includes(searchWorkoutAI.toLowerCase()) ||
            item.id.toLowerCase().includes(searchWorkoutAI)))
      );

      setDtWorkoutAIList(dtList)
    }

  },[workoutsAIList, searchWorkoutAI]);
  

  return (
    <div className="workout-creation">
      <Col md={12} className="text-start">
        <div className="text-start">
          <Row className="my-3">
            <Col lg={6}>
              <h5 className="mb-0">Workouts List</h5>
            </Col>
            <Col lg={6}>
              <Button
                variant="outline-primary"
                size="sm"
                className="mx-2 float-end"
                onClick={() => {
                  setTypeShow(true);
                  setIsEdit(false);
                  setWorkoutID(0);

                  handleReset();
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Create
              </Button>
            </Col>
          </Row>
        </div>

        <Row>
          <Col md={8} className="hide">
            <Form.Group
              className="mb-3 d-flex align-items-center"
              controlId="search_is_manual"
            >
              <Form.Label className="mb-0">Filter Manual Workout</Form.Label>
              <Form.Check // prettier-ignore
                className="form-switch mx-2"
                type="switch"
                id="search_is_manual"
                label=""
                checked={searchManual}
                onChange={(e) => {
                  setSearchManual(e.target.checked);
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey="manual"
          transition={false}
          id="workout-tab"
          className="mb-3 workout-tabs "
        >
          <Tab
            eventKey="manual"
            title={
              <span>
                <FontAwesomeIcon icon={faPersonBiking} /> Manual Workout
              </span>
            }
          >
            <Col md={8} className="float-start mb-3">
              <h6 className="my-3 text-start">
                <b>
                  Manual Workouts:{" "}
                  {
                    workoutList?.filter(
                      (item) =>
                        searchWorkout === "" ||
                        item.name
                          .toLowerCase()
                          .includes(searchWorkout.toLowerCase()) ||
                        item.id.toLowerCase().includes(searchWorkout)
                    ).length
                  }{" "}
                  of ({workoutList?.length} Workouts)
                </b>
              </h6>
            </Col>
            <Col md={4} className="float-end">
              <Form.Group
                className="mb-3"
                controlId="search_manual_workout"
              >
                <Form.Control
                  type="text"
                  value={searchWorkout}
                  onChange={(e) => setSearchWorkout(e.target.value)}
                  placeholder="Search Manual Workout"
                />
              </Form.Group>
            </Col>

            <div className="workout-dt">
              <DataTable
                columns={workoutColumns}
                data={dtWorkoutList}
                keyField="id"
                striped
                pagination
                paginationPerPage={10}
                searchable
                dense
                className="workoutDT"
              />
            </div>
          </Tab>

          <Tab
            eventKey="ai"
            title={
              <span>
                <FontAwesomeIcon icon={faMicrochip} /> AI Workout
              </span>
            }
          >
            <Col md={8} className="float-start mb-3">
              <h6 className="my-3 text-start">
                <b>
                  AI Workouts:{" "}
                  {
                    workoutsAIList?.filter(
                      (item) =>
                        searchWorkoutAI === "" ||
                        item.name
                          .toLowerCase()
                          .includes(searchWorkoutAI.toLowerCase()) ||
                        item.id.toLowerCase().includes(searchWorkoutAI)
                    ).length
                  }{" "}
                  of ({workoutsAIList?.length} Workouts)
                </b>
              </h6>
            </Col>
            <Col md={4} className="float-end">
              <Form.Group
                className="mb-3"
                controlId="search_ai_workout"
              >
                <Form.Control
                  type="text"
                  value={searchWorkoutAI}
                  name="searchWorkoutAI"
                  onChange={(e) => setSearchWorkoutAI(e.target.value)}
                  placeholder="Search AI Workout"
                />
              </Form.Group>
            </Col>

            <div className="workout-dt">
              <DataTable
                columns={workoutColumns}
                striped
                data={dtWorkoutAIList}
                pagination
                paginationPerPage={10}
                searchable
                dense
                className="workoutDT"
              />
            </div>
          </Tab>
        </Tabs>

        {/* <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Workout Name</th>
              <th>Workout Type</th>
              <th>Category</th>
              <th>Difficulty</th>
              <th>Duration</th>
              <th>Sets</th>
              <th>Exercises</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {workoutList?.length ? (
              workoutList
                ?.filter(
                  (item) =>
                    (searchWorkout === "" && !searchManual) ||
                    ((item.name
                      .toLowerCase()
                      .includes(searchWorkout.toLowerCase()) ||
                      item.id.toLowerCase().includes(searchWorkout)) &&
                      (searchManual ? item.is_manual : true))
                )
                ?.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.id}</td>
                    <td>{obj.name.replace(/_/g, " ")}</td>
                    <td>{obj.wtype_name}</td>
                    <td>{obj.category.toString()}</td>
                    <td>{obj.difficulty}</td>
                    <td>{obj.duration / 60} Mins</td>
                    <td>{obj.sets}</td>
                    <td className="text-center">{obj.exercise_obj?.length}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mx-2"
                        disabled={!obj.is_manual}
                        onClick={() => {
                          setIsEdit(true);
                          setTypeShow(true);

                          setWorkoutID(obj.id);

                          setWorkout(obj.name);
                          setWorkoutdescription(obj.description);

                          const result = categoryOptions.filter((data) =>
                            obj.category.includes(data.value)
                          );
                          setDefaultSelectedCategory(result);

                          setWorkoutCategory([obj.category[0]]);
                          setWorkoutDifficulty(obj.difficulty);
                          setWorkoutManual(obj.is_manual);
                          setWorkoutDuration(obj.duration.toString());
                          setWorkoutSets(obj.sets.toString());
                          setWorkoutTypeID(obj.wtype_id);
                          setWorkoutImage(obj.img_url);
                          setSelectedExercise("");
                          setSelectedDefaultExercise("");
                          setIsLoading(true)

                          API.get(`/workout-details/${obj.id}`)
                            .then((response) => {
                              let responseData =
                                response.data.data.aic_workout_details;
                              console.log(responseData);
                              setWorkoutExerciseObj(responseData);
                              setIsLoading(false)
                            })
                            .catch((error) => {
                              console.error(
                                "Error fetching Workout Details :",
                                error
                              );
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} /> Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="mx-2"
                        disabled={!obj.is_manual}
                        onClick={() => {
                          handleDelete(obj.id, "workouts");
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Button>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center">
                  No Workout List found
                </td>
              </tr>
            )}
          </tbody>
        </Table> */}

        {/* Modal Section */}
        <Modal size="lg" show={typeShow} onHide={() => setTypeShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="mb-0">
                {isEdit === true ? "Update" : "Create"} Workout
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={8}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_name"
                >
                  <Form.Label>Workout</Form.Label>
                  <Form.Control
                    type="text"
                    value={workout}
                    name="workoutName"
                    onChange={(e) => setWorkout(e.target.value)}
                    placeholder="Enter Workout"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="workout_descripton"
                >
                  <Form.Label>Description</Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={workoutdescription}
                    name="workoutDescription"
                    onChange={(e) => setWorkoutdescription(e.target.value)}
                    placeholder="Enter Workout Description"
                  />
                </Form.Group>
              </Col>

              <Col lg={4} md={4}>
                <Form.Label>Workout Image</Form.Label>
                <div className="exercise-image">
                  {workoutImage ? (
                    <img src={workoutImage} alt="Uploaded" />
                  ) : (
                    <img src={defaultWorkoutImage} alt="Uploaded-Default" />
                  )}
                </div>
                <Col lg={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    {/* <Form.Label>Exercise Image</Form.Label> */}
                    <Form.Control
                      type="file"
                      className="mt-2"
                      onChange={handleImageUpload}
                    />
                  </Form.Group>
                </Col>
              </Col>

              <Col lg={2} className="hide">
                <Form>
                  <Form.Label>Is Manual</Form.Label>
                  <Form.Check // prettier-ignore
                    className="form-switch"
                    type="switch"
                    id="is_manual"
                    label=""
                    checked={workoutManual}
                    onChange={(e) => {
                      setWorkoutManual(e.target.checked);
                    }}
                  />
                </Form>
              </Col>

              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_type"
                >
                  <Form.Label>Workout Type </Form.Label>

                  <Form.Select
                    aria-label="workout_type"
                    as="select"
                    value={workoutTypeID}
                    onChange={(e) => {
                      setWorkoutTypeID(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select Workout Type
                    </option>
                    {typeObj?.map((obj, index) => (
                      <option key={index} value={obj.id}>
                        {obj.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_category"
                >
                  <Form.Label>Category</Form.Label>
                  <Select
                    isMulti
                    name="category"
                    options={categoryOptions}
                    value={defaultSelectedCategory}
                    onChange={(e) => {
                      setDefaultSelectedCategory(e);
                      const tempArry = [];
                      e?.forEach((obj, index) => {
                        tempArry.push(obj.value);
                      });
                      setWorkoutCategory(tempArry);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_sets"
                >
                  <Form.Label>Sets</Form.Label>

                  <Form.Control
                    type="number"
                    value={workoutSets}
                    onChange={(e) => setWorkoutSets(e.target.value)}
                    placeholder="Enter Sets"
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_duration"
                >
                  <Form.Label>Duration</Form.Label>

                  <Form.Select
                    aria-label="duration"
                    as="select"
                    value={workoutDuration}
                    onChange={(e) => {
                      setWorkoutDuration(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select Duration
                    </option>
                    <option value="900">15 Mins</option>
                    <option value="1800">30 Mins</option>
                    <option value="2700">45 Mins</option>
                    <option value="3600">60 Mins</option>
                    <option value="5400">90 Mins</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_difficulty"
                >
                  <Form.Label>Difficulty</Form.Label>

                  <Form.Select
                    aria-label="difficulty"
                    as="select"
                    value={workoutDifficulty}
                    onChange={(e) => {
                      setWorkoutDifficulty(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select Difficulty
                    </option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Group
                  className="mb-3"
                  controlId="workout_content_url"
                >
                  <Form.Label>Content URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={workoutAudioUrl}
                    onChange={(e) => setWorkoutAudioUrl(e.target.value)}
                    disabled={workoutTypeID === "7" ? false : true}
                    placeholder="Enter Audio URL"
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="text-end">
              <Button
                variant="secondary"
                size="sm"
                className="mx-2"
                onClick={() => setTypeShow(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="mx-2"
                onClick={() => handleSubmitWorkout(workoutID)}
                type="submit"
              >
                <FontAwesomeIcon icon={faSave} />{" "}
                {isEdit === true ? " Update" : " Create"}
              </Button>
            </div>

            {/* Mapped Exercises List */}
            {isEdit === true ? (
              <div
                className="border-danger p-2 mt-3"
                style={{ border: "2px solid" }}
              >
                <h5 className="mb-3 bg-danger py-2 px-1 text-white">
                  <FontAwesomeIcon icon={faRunning} />{" "}
                  {workout.replace(/_/g, " ")} Workout - Mapping
                </h5>

                {isLoading ? (
                  <ExercisePreloader />
                ) : (
                  <>
                    <Row>
                      <Col md={6}>
                        <Select
                          className="mb-3 text-start"
                          name="exercises"
                          options={getCategoryOptions(workoutID)}
                          value={selectedDefaultExercise}
                          formatOptionLabel={formatOptionLabel}
                          placeholder="Select Exercise"
                          onChange={(e) => {
                            setSelectedExerciseName(e.label);
                            setSelectedExercise(e.value);
                            setSelectedDefaultExercise(e);
                          }}
                        />
                      </Col>

                      <Col md={6}>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="m-1"
                          onClick={() => handleAddExercise()}
                        >
                          <FontAwesomeIcon icon={faAdd} /> Add Mapping
                        </Button>
                      </Col>
                    </Row>

                    {/* Drag and Drop */}

                    {workoutExerciseObj.length ? (
                      <DndProvider backend={HTML5Backend}>
                        <DragAndDropExercise
                          workoutID={workoutID}
                          handleRemoveExercise={handleRemoveExercise}
                          sortExericseOrder={sortExericseOrder}
                        />
                      </DndProvider>
                    ) : (
                      <div className="text-center p-4">No Exercises Mapped</div>
                    )}
                  </>
                )}

                {/* {workoutsList.length &&
                  workoutsList
                    .filter((obj) => parseInt(obj.id) === parseInt(workoutID))
                    ?.map((obj, index) => <div key={index}></div>)} */}
                {/* Drag and Drop */}
              </div>
            ) : (
              <></>
            )}
            {/* Mapped Exercises List */}
          </Modal.Body>
        </Modal>
        {/* End Modal Section */}
      </Col>
    </div>
  );
};

export default WorkoutCreation;
