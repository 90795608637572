import "./App.css";
import React, { useState } from "react";
import Cookies from "universal-cookie";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
  const cookies = new Cookies();
  const [signed, setSigned] = useState(
    cookies.get("access_token") ? true : false
  );
  const [mountLogin, setMountLogin] = useState(
    cookies.get("access_token") ? false : true
  );
  return (
    <div className="App">
       <ToastContainer />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Protected signed={signed}>
                  <HomePage
                    setMountLogin={setMountLogin}
                    setSigned={setSigned}
                  />
                </Protected>
              }
            />
            <Route
              path="/login"
              element={
                <LoginRedirect signed={mountLogin}>
                  <Login setSigned={setSigned} />
                </LoginRedirect>
              }
            />
          </Routes>
        </Router>      
    </div>
  );
}

export default App;

function Protected({ signed, children }) {
  if (!signed) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

function LoginRedirect({ signed, children }) {
  if (!signed) {
    return <Navigate to="/" replace />;
  }
  return children;
}
