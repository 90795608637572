import React, { useContext, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import CreateEntity from "./CreateEntity";
import Cookies from "universal-cookie";
import WorkoutCreation from "./WorkoutCreation";
import ExerciseOutput from "./ExerciseOutput";
import { useNavigate } from "react-router";
// import Mapping from "./Mapping";
// import ExerciseCreation from "./ExerciseCreation";
// import { getWorkoutTypeAPI } from "../common/getWorkoutAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import FilterExercises from "./FilterExercises";
import DataContext from "../context/DataContext";
import preloaderGif from "../assets/img/preloader-7.gif";
import ActivityTemplate from "./ActivityTemplate";

const HomePage = ({ setSigned, setMountLogin }) => {
  
  const { isPreLoading } = useContext(DataContext);

  const Preloader = () => {
    return (
      <div className="preloader-section">
        <img src={preloaderGif} alt="Loading..." />
      </div>
    );
  };

  return (
    <div className="container py-2">
      {isPreLoading ? (
        <Preloader />
      ) : (
        <>
          <header className="App-header">
            <h4 className="my-2 heading-section">
              <img
                src={require("../assets/img/smitch-logo.png")}
                alt=""
                className="ai-coach-icon"
              />
              SMITCH AI COACH
            </h4>
            <div className="float-end">
              <Logout setSigned={setSigned} setMountLogin={setMountLogin} />
            </div>
          </header>
          <div className="">
            <div className="py-3">
              <Tabs
                defaultActiveKey="home"
                transition={false}
                id="homepage-tab"
                className="mb-3 ai-coach-tabs"
              >
                <Tab eventKey="home" title="Home">
                  <CreateEntity />
                </Tab>
                {/* <Tab eventKey="mapping" title="Mapping">
                  <Mapping />
                </Tab> */}
                {/* <Tab eventKey="exercise_creation" title="Exercise Mapping">
                  <ExerciseCreation />
                </Tab> */}
                <Tab eventKey="workout" title="Workout Creation">
                  <WorkoutCreation />
                </Tab>
                <Tab eventKey="workout_details" title="Workout Details">
                  <ExerciseOutput />
                </Tab>
                <Tab eventKey="filter_exercises" title="Filter Exercises">
                  <FilterExercises />
                </Tab>
                <Tab eventKey="activity_template" title="Activity Template">
                  <ActivityTemplate />
                </Tab>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomePage;

const Logout = ({ setSigned, setMountLogin }) => {
  const navigate = useNavigate();
  const [logged, setLogged] = useState(true);
  const cookies = new Cookies();
  const handleLogin = () => {
    cookies.remove("access_token");
    setLogged(false);
    setSigned(false);
    setMountLogin(true);
    navigate("/login");
  };
  return logged ? (
    <Button onClick={handleLogin} variant="outline-danger">
      <FontAwesomeIcon icon={faSignOut} /> {" "}
       Logout
    </Button>
  ) : (
    <></>
  );
};